import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout";


// markup
const NotFoundPage = () => {
  return (
    <Layout title="Not found" isBlurred>
      <div className="text-center mb-4">
        <h1 className="text-7xl">Sorry, page not found...</h1>
        <p className="text-xl">
          Please don't feel hate{" "}<span role="img" aria-label="Winky face emoji">
        😉
        </span>{" "}
          <br />
        </p>
        <Link to="/">
          <button className="btn">Go Home</button>
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
